import { NgModule } from "@angular/core";
import { Router, RouterModule } from "@angular/router";

import { desktopRoutes, mobileRoutes } from "./constants/routes";
import { ApplicationStateService } from "./services/application-state.service";

@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule],
})
export class AppRoutingModule {
  public constructor(
    private router: Router,
    private applicationStateService: ApplicationStateService
  ) {
    this.initializeRoutes();
  }

  /**
   * Initializes the application routes based on device resolution.
   *
   * @remarks
   * This method checks if the current device is mobile/tablet and
   * configures the appropriate routing setup. For mobile/tablet
   * devices, it loads the mobile routes. For desktop devices, it resets
   * mobile flags and loads desktop routes.
   *
   * The mobile check is done by checking both mobile and tablet
   * resolution flags from the application state service.
   */
  private initializeRoutes(): void {
    const isMobile =
      this.applicationStateService.isMobileResolution ||
      this.applicationStateService.isTabletResolution;

    if (isMobile) {
      this.router.resetConfig(mobileRoutes);
    } else {
      this.applicationStateService.isMobileResolution = false;
      this.applicationStateService.isTabletResolution = false;
      this.router.resetConfig(desktopRoutes);
    }
  }
}
